<template>
  <div class="footer" :class="{ crosswise_footer: !vertical }">
    <div class="footer_cont">
      <div class="footer_flex">
        <div class="f_left">
          <span>支付：</span>
          <span>￥{{ (orderInfo.amount/100).toFixed(2) }}</span>
        </div>
        <div class="f_right" @click="commitPayBtn">确认支付</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import sdkApi from '@/api/api'
export default {
  name: "GeneralFooter",
  data() {
    return {};
  },
  created() {},
  computed: {
    ...mapState(["vertical", "orderInfo"]),
  },
  methods: {
    async commitPayBtn() {
      const payRes = await sdkApi.confirmPay({channel: this.$store.state.payWay.client_id});
      if (!payRes) return
      window.location.href = payRes.url
    }
  },
};
</script>

<style lang="scss">
.footer {
  width: 100%;
  background: #fff;
  position: fixed;
  box-sizing: border-box;
  bottom: 0;
  left: 0;
  .footer_cont {
    width: 100%;
    position: relative;
    .footer_flex{
      display: flex;
      padding: 0.2rem 0.3rem; 
      justify-content: space-between;
      align-items: center;
      .f_left{
        display: flex;
        align-items: center;
        font-size: 0.4rem;
        span:nth-child(2) {
          font-size: 0.6rem;
          font-weight: bold;
          color: #fb4414;
        }
      }
      .f_right{
        padding: 0.2rem 1rem;
        background: #fb4414;
        color: #fff;
        border-radius: 0.2rem;
      }
    }
  }
}

// 横屏部分
#app .crosswise_footer{
  .footer_cont {
    .footer_flex {
      .f_left {
        font-size: 0.24rem;
        span:nth-child(2) {
          font-size: 0.35rem;
        }
      }
      .f_right{
        padding: 0.18rem 1rem;
        font-size: 0.24rem;
        border-radius: 0.1rem;
      }
    }
  }
}
</style>
