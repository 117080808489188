import axios from 'axios';
import qs from 'qs';
import { getSignedParams, getUrlParams } from '@/assets/js/utils';
import { Dialog } from 'vant';

axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
const http = {
  create(option = {}) {
    const _axios = axios.create(option);
    // 为实例添加请求拦截器
    _axios.interceptors.request.use(
      config => {
        const { method, params = {} } = config;
        let newParams;
        if (method === 'post') {
          // post参数也通过params传递
          config.data = params;
          config.params = '';
          newParams = config.data;
        } else if (method === 'get') {
          newParams = config.params;
        }
        newParams.id = getUrlParams('order_id')
        // 增加sign参数
        const app_key = 'U2FLJKh7hq9gxHXbeEXhC7CU=';
        const signedParams = getSignedParams(app_key, newParams);
        newParams = signedParams;
        if (method === 'post') {
          config.data = qs.stringify(newParams);
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
    // 为实例添加响应拦截器
    _axios.interceptors.response.use(
      response => {
        const { isConfirm } = response.config;
        // 将返回的JSON转成对象
        const apiData = response.data || {};
        let { msg } = apiData;
        if (isConfirm) {
          Dialog.alert({
            message: msg
          });
        }
        if (apiData.code != 0) {
          Dialog.alert({
            message: msg
          });
        } else {
          return apiData.data || {};
        }
      },
      error => {
        return Promise.reject(error);
      }
    );
    return _axios;
  }
};

export default http;
