<template>
  <div class="content" :class="{ crosswise_content: !vertical }">
    <div class="content_ways">

      <div class="ways_list" v-for="(item,index) in payWay" :class="{curr_ways: item.check}" :key="item.client_id" @click="changePayWay(item, index)">
        <div class="list_dt">
          <div><img :src="item.icon" alt=""></div>
          <div>
            <p>{{item.name}}</p>
            <p v-if="item.show_discount==1"><img src="../assets/images/pay_reduce.png" alt=""></p>
          </div>
        </div>
        <div class="list_ck">
          <img v-if="item.check" src="../assets/images/ln_pay_checked.png" alt="">
          <img v-else src="../assets/images/ln_pay_check.png" alt="">
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import sdkApi from '@/api/api'
export default {
  name: "GeneralContent",
  data() {
    return {
      payWay: [],
      currIndex: 0,
    };
  },
  created() {
    this.getInitPayWay();
  },
  computed: {
    ...mapState(["vertical"]),
  },
  mounted() {
    // 默认选中第1个支付方式
    this.$store.commit('SET_PAY_WAY', this.payWay[0]);
  },
  watch: {
    payWay: {
      handler(newVal) {
        this.$store.commit('SET_PAY_WAY', newVal && newVal.find(item => item.check));
      },
      deep: true
    }
  },
  methods: {
    changePayWay(data, index) {
      this.payWay.forEach(item => {item.check = false});
      this.$set(this.payWay, index, {...data, check: true})
    },
    async getInitPayWay() {
      this.payWay = await sdkApi.getPayWay()
      this.payWay.forEach((v,vx) => {
        v.check = vx == 0 ? true : false;
        v.icon = this.payIcon(v.pay_type);
      });
    },
    payIcon(type) {
      switch (type) {
        case 'alipay':
          return require('../assets/images/kdalipay.png')
        case 'huabei':
          return require('../assets/images/huabei.png')
        case 'weixin':
          return require('../assets/images/wechatpay.png')   
        case 'upacp':
          return require('../assets/images/upacp.png')  
        default:
          break;
      }
    }
  },
};
</script>

<style lang="scss">
.content {
  padding: 0.3rem 0.4rem;
  .content_ways {
    width: 100%;
    .ways_list{
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 0.02rem solid rgb(220,220,220);
      border-radius: 0.2rem;
      padding: 0.15rem 0.2rem;
      margin-bottom: 0.3rem;
      .list_dt{
        display: flex;
        align-items: center;
        div:nth-child(1) img{
          width: 0.8rem;
        }
        div:nth-child(2) {
          p:nth-child(2) {
            margin-top: 0.05rem;
          }
          p:nth-child(2) img {
            width: 3.5rem;
          }
          margin-left: 0.3rem;
        }
      }
      .list_ck{
        img {
          width: 0.65rem;
        }
      }
    }
    .curr_ways{border: 0.04rem solid rgb(249,129,41) !important;}
  }
}

// 横屏部分
#app .crosswise_content {
  padding: 0.2rem 0.3rem;
  margin-bottom: 1rem;
  .content_ways{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .ways_list{
      padding: 0.05rem 0.1rem;
      margin-bottom: 0.2rem;
      border-radius: 0.1rem;
      height: 0.65rem;
      width: 46%;
      .list_dt {
        font-size: 0.24rem;
        div:nth-child(1) img {
          width: 0.4rem;
        }
        div:nth-child(2) {
          p:nth-child(2) {
            margin-top: 0.05rem;
          }
          p:nth-child(2) img {
            width: 2rem;
          }
          margin-left: 0.3rem;
        }
      }
      .list_ck img{
        width: 0.35rem;
      }
    }
  }
}
</style>
