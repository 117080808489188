import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    vertical: window.innerWidth > window.innerHeight ? false : true,
    payWay: {},
    orderInfo: {amount: 0},
  },
  mutations: {
    SET_PAY_WAY(state, val) {
      state.payWay = val
    },
    SET_VERTICAL(state, val) {
      state.vertical = val
    },
    SET_ORDERINFO(state, val) {
      state.orderInfo = val
    }
  },
});
