<template>
    <div id="app">
      <Header v-if="!isAndroid" />
      <role />
      <Footer/>
      <Content/>
    </div>
  </template>
  
  <script>
  import Header from '@/components/header.vue'
  import Footer from '@/components/footer.vue'
  import Role from '@/components/role.vue'
  import Content from '@/components/content.vue'
  // import sdkApi from '@/api/api'
  export default {
    name: 'HomeIndex',
    components: {
      Header,
      Footer,
      Role,
      Content
    },
    data() {
      return {
        isAndroid: false,
      }
    },
    created() {
      if (typeof(leniuJS) !== 'undefined') {
        this.isAndroid = true;
      }
    },
    mounted() {
      window.onresize = () => {
        this.$store.commit('SET_VERTICAL', window.innerWidth > window.innerHeight ? false : true)
      }
    },
    methods: {
    },
  }
  </script>
  
  <style lang="scss">
  </style>
  