import _http from "./http";
console.log(process.env.NODE_ENV)
const http = _http.create({
  serverType: "channel",
  baseURL: process.env.NODE_ENV=='development' ? "http://papi-test.lnqwe.com/" : 'https://papisdk.lnqwe.com/',
});
export default class SdkPayApi {
  static queryOrder = params => {
    return http({
      url: "/sdk/o/query",
      method: "post",
      params,
    });
  }
  static getPayWay = params => {
    return http({
      url: "/sdk/pay/payments",
      method: "post",
      params,
    });
  }
  static confirmPay = params => {
    return http({
      url: "/sdk/pay/confirm",
      method: "post",
      params,
    });
  }
}
