import md5 from 'js-md5';

// 同步获取uuid
export const getUUids = () => {
  if (getUrlParams('uuid')) {
    return getUrlParams('uuid')
  } else {
    let timeStr = new Date().valueOf() + Math.floor((Math.random()*10000000)+1) + '';
    let timeMD5 = timeStr;
    let newUUID = md5(timeMD5);
    if (localStorage.getItem('lntimeuuid')) {
      return localStorage.getItem('lntimeuuid');
    } else {
      localStorage.setItem('lntimeuuid', newUUID);
      return newUUID;
    }
  }
}

// 获取URL的单个参数
const getUrlSingleParams = (name) => {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  const r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]);
  return '';
};

// 获取URL的参数，兼容单个或多个
export const getUrlParams = (arg1, url) => {
  if (typeof arg1 === 'string') {
    return getUrlSingleParams(arg1, url);
  } else if (Array.isArray(arg1)) {
    let obj;
    for (const key of arg1) {
      obj[key] = getUrlSingleParams(key, url);
    }
    return obj;
  }
};

// 获取ascii排序后的查询字符串
const getSortAsciiQuery = obj => {
  const arr = [];
  let num = 0;
  for (const i in obj) {
    arr[num] = i;
    num++;
  }
  const sortArr = arr.sort();
  // let sortObj = {};    //完成排序值
  let str = ''; // 自定义排序字符串
  for (const i in sortArr) {
    str += sortArr[i] + '=' + obj[sortArr[i]] + '&';
    // sortObj[sortArr[i]] = obj[sortArr[i]];
  }
  // 去除两侧字符串
  const char = '&';
  str = str.replace(new RegExp('^\\' + char + '+|\\' + char + '+$', 'g'), '');
  return str;
};

// 获取加密后的sign值
export const getSign = (app_key = '', params = {}) => {
  const sortedParamsQuery = getSortAsciiQuery(params);
  const app_key_params = app_key ? `${app_key}` : '';
  const resultQuery = `${sortedParamsQuery}${app_key_params}`;
  const sign = md5(resultQuery);
  console.log('签名:', resultQuery);
  console.log('签名:', sign);
  // console.log('MD5加密后:', sign);
  return sign;
};

export const getSignStatis = (app_key = '', params = {}) => {
  const sortedParamsQuery = getSortAsciiQuery(params);
  const app_key_params = app_key ? `${app_key}` : '';
  const resultQuery = `${sortedParamsQuery}${app_key_params}`;
  const sign = md5(resultQuery);
  console.log('签名:', resultQuery);
  console.log('签名:', sign);
  // console.log('MD5加密后:', sign);
  return sign;
};

// 获取增加sign值后的params
export const getSignedParams = (app_key, params = {}) => {
  const sign = getSign(app_key, params);
  params.sign = sign;
  return params;
};

// 获取PHP时间戳
export const getPhpNow = () => {
  const now = new Date().getTime();
  return +`${now}`.slice(0, -3);
};


// 获取分辨率
export const getScreen = () => {
  const { width, height } = screen;
  console.log(screen);
  return `${width}*${height}`;
};

// 打开新窗口
export const openUrl = url => {
  var a = document.createElement('a');
  a.href = url;
  a.target = '_blank';
  document.body.appendChild(a);
  var e = document.createEvent('MouseEvents');
  e.initEvent('click', true, true);
  a.dispatchEvent(e);
};
