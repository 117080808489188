<template>
  <div class="header" :class="{crosswise_header: !vertical}">
    <div>支付中心</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: "GeneralHeader",
  data() {
    return {};
  },
  created() {
  },
  computed: {
    ...mapState(['vertical'])
  },
  methods: {},
};
</script>

<style lang="scss">
.header {
  width: 100%;
  height: 1.2rem;
  background: url(../assets/images/pay_title.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  box-sizing: border-box;
  line-height: 1.2rem;
}

// 横屏部分
#app .crosswise_header{
  height: 0.75rem;
  line-height: 0.75rem;
  div {
    font-size: 0.26rem;
  }
}
</style>
