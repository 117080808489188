<template>
  <div class="role" :class="{ crosswise_role: !vertical }">
    <div class="role_cont">
      <div class="prod">
        <span>商品</span>
        <span>{{ roleInfo.order_name }}</span>
      </div>
      <div class="desc">
        <span>角色名</span>
        <span>{{ roleInfo.rolename }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import sdkApi from '@/api/api'
export default {
  name: "GeneralRole",
  data() {
    return {
      roleInfo: {},
    };
  },
  created() {
    this.getRoleInfo();
  },
  computed: {
    ...mapState(["vertical"]),
  },
  methods: {
    async getRoleInfo() {
      this.roleInfo = await sdkApi.queryOrder();
      this.$store.commit('SET_ORDERINFO', this.roleInfo);
    }
  },
};
</script>

<style lang="scss">
.role {
  width: 100%;
  .role_cont {
    padding: 0.3rem 0.4rem;
    .prod {
      font-size: 0.65rem;
      display: flex;
      font-weight: bold;
      color: rgb(57,57, 57);
      span:nth-child(1) {
        width: 2rem;
      }
    }
    .desc {
      font-size: 0.4rem;
      display: flex;
      margin-top: 0.15rem;
      color: rgb(161,161,161);
      span:nth-child(1) {
        width: 2rem;
      }
    }
  }
}

// 横屏部分
#app .crosswise_role {
  .role_cont {
    padding: 0.2rem 0.3rem 0rem;
    .prod {
      font-size: 0.4rem;
      span:nth-child(1) {
        width: 1.1rem;
      }
    }
    .desc {
      margin-top: 0.1rem;
      font-size: 0.25rem;
      span:nth-child(1) {
        width: 1.1rem;
      }
    }
  }
}
</style>
