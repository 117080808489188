import Vue from 'vue'
import Home from './views/index.vue'
import store from './store'
import 'vant/lib/index.css'
Vue.config.productionTip = false
import './assets/css/common.css'

new Vue({
  store,
  render: h => h(Home),
}).$mount('#app')
